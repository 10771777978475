import ASIAllianceIcon from '../asi-icon.png';
import KeplrIcon from '../keplr.png';
import { useState } from 'react';
import Modal from './Modal';
import { detectASIWallet, detectKeplr } from '../keplr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface WalletModal {
  connectWallet: (type: string) => Promise<void>;
  isOpen: boolean;
  setWalletModalOpen: (value: boolean) => void;
  isLoading: boolean;
}

const WALLET_OPTIONS = [
  {
    name: 'ASI Alliance Wallet',
    type: 'asiWallet',
    icon: ASIAllianceIcon,
    detectWallet: detectASIWallet,
  },
  {
    name: 'Keplr',
    type: 'keplr',
    icon: KeplrIcon,
    detectWallet: detectKeplr,
  },
];

export const WalletConnectModal: React.FC<WalletModal> = ({
  isOpen,
  isLoading,
  connectWallet,
  setWalletModalOpen,
}) => {
  const [walletType, setWalletType] = useState<string>('');

  const onClose = () => {
    setWalletModalOpen(false);
    setWalletType('');
  };

  const handleWalletConnection = async (type: string) => {
    setWalletType(type);
    await connectWallet(type);
    onClose();
    setWalletType('');
  };

  return (
    <Modal
      isOpen={isOpen}
      position="center"
      modalHeader="Connect Your Wallet"
      contentClassname="wallet-connect-modal"
      content={
        <div className="wallet-container">
          {WALLET_OPTIONS.map((wallet: any, index: number) => (
            <div key={index} className="wallet-card">
              <span className="wallet-icon">
                <img alt="icon" src={wallet.icon} />
              </span>
              <p className="ml-4">{wallet.name}</p>
              <button
                className="connect-button"
                onClick={() => {
                  if (!isLoading) {
                    handleWalletConnection(wallet.type);
                  }
                }}
                disabled={isLoading || !wallet?.detectWallet()}
              >
                {isLoading && walletType === wallet.type ? (
                  <div className="spinner-container">
                    <div>Connecting</div>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      size="sm"
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                ) : (
                  'Connect'
                )}
              </button>
            </div>
          ))}
        </div>
      }
      onClose={onClose}
    />
  );
};

import ReactModal from 'react-modal';
import clsx from 'clsx';
import CloseIcon from '../close.svg';

interface ModalProps {
  isOpen: boolean;
  modalHeader?: React.ReactNode;
  onClose: () => void;
  content: React.ReactNode;
  position?: 'top' | 'bottom' | 'center';
  contentClassname?: string;
  headerClassname?: string;
  showCloseIcon?: boolean;
}
if (document.getElementById('root')) {
  ReactModal.setAppElement('#root');
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  content,
  onClose,
  modalHeader,
  position = 'bottom',
  contentClassname,
  headerClassname,
  showCloseIcon = true,
}) => {
  const modalClass = clsx('modal', {
    'modal-bottom': position === 'bottom',
    'modal-top': position === 'top',
    'modal-center': position === 'center',
  });

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      className={modalClass}
      overlayClassName="overlay"
    >
      <div className={`card-container content ${contentClassname || ''}`}>
        {(showCloseIcon || modalHeader) && (
          <div className={`header ${headerClassname || ''}`}>
            {modalHeader && <p className="text-2xl">{modalHeader}</p>}
            {showCloseIcon && (
              <div className="close-button" onClick={onClose}>
                <img alt="close" src={CloseIcon} />
              </div>
            )}
          </div>
        )}
        {content}
      </div>
    </ReactModal>
  );
};

export default Modal;

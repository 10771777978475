import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { TextInput } from './TextInput';
import { snooze, validateAmount, ValidationState } from '../utils';
import { NativeBridgeStatus } from '../contracts/nativeBridge';
import Decimal from 'decimal.js';
import { BECH32_PREFIX, Ledger } from '../ledger';
import { SwapState, TransferButton } from './TransferButton';
import {
  AccountDetails,
  queryAccountDetails,
  swap,
} from '../contracts/nativeNetwork';
import { CONFIG, SWAP_CONFIG } from '../config';
import { setInterval } from 'timers';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EthBridgeStatus } from '../contracts/ethBridge';
import { OfflineSigner } from '@cosmjs/proto-signing';
import { Bech32Address } from '@keplr-wallet/cosmos';
import { detectASIWallet, detectKeplr } from '../keplr';
import { WalletConnectModal } from './WalletConnectModal';

const DEFAULT_AMOUNT = '';

interface NativeToEthTransferProps {
  nativeAddress: string;
  ethAddress: string;
  nativeStatus?: NativeBridgeStatus;
  ethStatus?: EthBridgeStatus;
  signer?: OfflineSigner;
  setSigner: (signer: OfflineSigner) => void;
  setNativeAddress: (nativeAddress: string) => void;
}

function isCapReached(status?: NativeBridgeStatus) {
  if (status === undefined) {
    return true;
  }

  return status.supply.greaterThanOrEqualTo(status.cap);
}

export function NativeToEthTransfer(props: NativeToEthTransferProps) {
  const [swapState, setSwapState] = useState<SwapState>(SwapState.NOT_STARTED);
  const [amount, setAmount] = useState<string>(DEFAULT_AMOUNT);
  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const [connectionInProgress, setConnectionInProgress] = useState<
    'idle' | 'ledger' | 'keplr'
  >('idle');
  const [nativeAccount, setNativeAccount] = useState<
    AccountDetails | undefined
  >();
  const [ledgerErrorMsg, setLedgerErrorMessage] = useState<
    string | undefined
  >();

  const lookupAccountDetails = async (address: string) => {
    let details = undefined;
    try {
      details = await queryAccountDetails(address);
    } catch (e) {
      console.log('Unable to lookup native account details', e);
    }
    setNativeAccount(details);
  };

  useEffect(() => {
    if (ledgerErrorMsg === undefined) {
      return;
    }

    setTimeout(() => setLedgerErrorMessage(undefined), 2000);
  }, [ledgerErrorMsg]);

  useEffect(() => {
    if (props.nativeAddress === '') {
      return;
    }

    const interval = setInterval(async () => {
      await lookupAccountDetails(props.nativeAddress);
    }, 5000);
    return () => clearInterval(interval);
  }, [props.nativeAddress]);

  useEffect(() => {
    const keplr = detectKeplr();
    if (keplr === undefined) {
      return;
    }

    if (props.signer === undefined) {
      return;
    }

    const handler = async () => {
      try {
        const { bech32Address } = await keplr.getKey(CONFIG.fetch.chainId);
        props.setNativeAddress(bech32Address);
      } catch {
        console.warn('Unable to query address from change event');
      }
    };

    window.addEventListener('keplr_keystorechange', handler);
    return () => {
      window.removeEventListener('keplr_keystorechange', handler);
    };
  }, [props]);

  const numAmount: Decimal = useMemo(() => {
    if (validateAmount(amount) === ValidationState.VALID) {
      return new Decimal(amount).mul('1e18');
    } else {
      return new Decimal('0');
    }
  }, [amount]);

  const formHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    async function resetForm(success: boolean) {
      if (success) {
        setSwapState(SwapState.COMPLETE);
      } else {
        setSwapState(SwapState.FAILURE);
      }

      await snooze(2000);

      // if successful reset the values of the form back to defaults
      if (success) {
        setAmount(DEFAULT_AMOUNT);
      }
      setSwapState(SwapState.NOT_STARTED);
    }

    // this should not happen in the UI but add a sanity check here
    if (
      props.nativeAddress === '' ||
      nativeAccount === undefined ||
      props.signer === undefined
    ) {
      await resetForm(false);
      return;
    }

    try {
      setSwapState(SwapState.WAITING_FOR_SWAP);

      await resetForm(
        await swap(
          props.signer,
          props.nativeAddress,
          props.ethAddress,
          CONFIG.fetch.contractAddress,
          { amount: numAmount.toFixed(0), denom: CONFIG.fetch.denom }
        )
      );
    } catch (e) {
      console.log('Swap error', e);

      await resetForm(false);
    }
  };

  const connectLedgerDevice = async () => {
    setConnectionInProgress('ledger');

    try {
      const ledger = await Ledger.connect();
      const [address] = await ledger.getAddress();

      await lookupAccountDetails(address);

      props.setSigner(ledger);
      props.setNativeAddress(address);

      setLedgerErrorMessage(undefined);
    } catch (e: any) {
      let message: string | undefined = e.message?.toString();
      if (message !== undefined && message.startsWith('Unknown Status Code')) {
        message = undefined;
      }

      setLedgerErrorMessage(message ?? 'Failed to connect to device');
    }

    setConnectionInProgress('idle');
  };

  const connectBrowserWallet = async (type: string) => {
    setConnectionInProgress('keplr');

    const finishConnect = (message?: string) => {
      setConnectionInProgress('idle');
      if (message !== undefined) {
        setLedgerErrorMessage(message);
      }
    };

    const keplr = type === 'asiWallet' ? detectASIWallet() : detectKeplr();
    if (keplr === undefined) {
      finishConnect('Unable to detect browser extension');
      return;
    }

    let configuredChain = false;
    try {
      await keplr.enable(CONFIG.fetch.chainId);
      configuredChain = true;
    } catch {
      // acceptable
    }

    if (!configuredChain) {
      try {
        await keplr.experimentalSuggestChain({
          rpc: CONFIG.fetch.rpcUrl,
          rest: CONFIG.fetch.restUrl,
          chainId: CONFIG.fetch.chainId,
          chainName: CONFIG.fetch.chainName,
          stakeCurrency: {
            coinDenom: CONFIG.fetch.tokenTicker,
            coinMinimalDenom: CONFIG.fetch.denom,
            coinDecimals: 18,
            coinGeckoId: 'fetch-ai',
          },
          bip44: {
            coinType: 118,
          },
          bech32Config: Bech32Address.defaultBech32Config(BECH32_PREFIX),
          currencies: [
            {
              coinDenom: CONFIG.fetch.tokenTicker,
              coinMinimalDenom: CONFIG.fetch.denom,
              coinDecimals: 18,
            },
          ],
          gasPriceStep: {
            low: 0,
            average: 5000000000,
            high: 6500000000,
          },
          feeCurrencies: [
            {
              coinDenom: CONFIG.fetch.tokenTicker,
              coinMinimalDenom: CONFIG.fetch.denom,
              coinDecimals: 18,
            },
          ],
          coinType: 118,
          features: [],
        });

        configuredChain = true;
      } catch {
        // user might not accept
      }
    }

    if (!configuredChain) {
      finishConnect('Unable to connect browser wallet');
      return;
    }

    try {
      const { bech32Address } = await keplr.getKey(CONFIG.fetch.chainId);
      const signer = keplr.getOfflineSigner(CONFIG.fetch.chainId);

      props.setSigner(signer);
      props.setNativeAddress(bech32Address);

      finishConnect();
    } catch {
      finishConnect('Failed to lookup account');
    }
  };

  const maxSingleNativeSwap: Decimal | undefined =
    SWAP_CONFIG.maxSingleNativeSwap !== undefined
      ? new Decimal(SWAP_CONFIG.maxSingleNativeSwap)
      : undefined;

  let validationAmount = validateAmount(amount);
  let validationAmountText = 'Invalid amount';
  if (
    validationAmount === ValidationState.VALID &&
    props.nativeStatus !== undefined &&
    props.ethStatus !== undefined
  ) {
    const nextSupply = props.nativeStatus.supply.add(numAmount);

    if (numAmount.lessThan(props.nativeStatus.swapMin)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount too small. Min: ${props.nativeStatus.swapMin
        .div('1e18')
        .toString()} FET`;
    } else if (numAmount.greaterThan(props.nativeStatus.swapMax)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount too large. Max: ${props.nativeStatus.swapMax
        .div('1e18')
        .toString()} FET`;
    } else if (numAmount.greaterThan(props.ethStatus.swapMax)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount too large. Max: ${props.ethStatus.swapMax
        .div('1e18')
        .toString()} FET`;
    } else if (
      maxSingleNativeSwap !== undefined &&
      numAmount.greaterThan(maxSingleNativeSwap)
    ) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount too large. A single native swap is currently limited to ${maxSingleNativeSwap
        .div('1e18')
        .toString()} FET`;
    } else if (
      nativeAccount !== undefined &&
      numAmount.greaterThan(nativeAccount.balance)
    ) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount larger than available FET balance`;
    } else if (nextSupply.greaterThan(props.nativeStatus.cap)) {
      validationAmount = ValidationState.INVALID;

      if (
        props.nativeStatus.supply.greaterThanOrEqualTo(props.nativeStatus.cap)
      ) {
        validationAmountText = `The token bridge has reached its current cap, no further transfers possible at this time`;
      } else {
        const remaining = props.nativeStatus.cap.sub(props.nativeStatus.supply);
        validationAmountText = `Amount would breach the bridge cap. Remaining allowance: ${remaining
          .div('1e18')
          .toString()} FET`;
      }
    } else if (numAmount.greaterThan(props.ethStatus.reverseAggLimit)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount is larger than the current bridge limit. Remaining allowance ${props.ethStatus.reverseAggLimit
        .div('1e18')
        .toString()}`;
    } else if (numAmount.greaterThan(props.ethStatus.supply)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount is larger than the current bridge supply. Remaining amount ${props.ethStatus.supply
        .div('1e18')
        .toString()}`;
    }
  }

  const isLoading =
    props.ethStatus === undefined || props.nativeStatus === undefined;
  const isPaused =
    (props.nativeStatus?.paused ?? true) || (props.ethStatus?.paused ?? true);
  const capReached = isCapReached(props.nativeStatus);
  const isValid =
    props.nativeAddress !== '' &&
    validationAmount === ValidationState.VALID &&
    !capReached &&
    !isPaused;

  let transferFee = 0;
  if (props.ethStatus !== undefined && props.ethStatus.fee.greaterThan(0)) {
    transferFee = props.ethStatus.fee.dividedBy('1e18').toNumber();
  }

  return (
    <React.Fragment>
      <form className="InputForm" noValidate onSubmit={formHandler}>
        <TextInput
          name="Native Address"
          value={props.nativeAddress}
          placeholder={ledgerErrorMsg}
          readOnly={true}
        />

        {props.nativeAddress === '' && (
          <div className="ButtonGroup">
            <button
              className="ConnectButton"
              disabled={connectionInProgress !== 'idle'}
              onClick={() => setConnectModalOpen(true)}
            >
              {connectionInProgress === 'keplr' ? 'Connecting to' : 'Connect'}{' '}
              Browser Wallet
              {connectionInProgress === 'keplr' && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="sm"
                  style={{ marginLeft: 10 }}
                />
              )}
            </button>
            <button
              className="ConnectButton"
              disabled={connectionInProgress !== 'idle'}
              onClick={connectLedgerDevice}
            >
              {connectionInProgress === 'ledger' ? 'Connecting to' : 'Connect'}{' '}
              Ledger
              {connectionInProgress === 'ledger' && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="sm"
                  style={{ marginLeft: 10 }}
                />
              )}
            </button>
          </div>
        )}

        <TextInput
          name="Eth Address"
          value={props.ethAddress}
          readOnly={true}
        />

        <TextInput
          name="Amount"
          value={amount}
          onChange={setAmount}
          invalidText={validationAmountText}
          validation={validationAmount}
        />

        {transferFee > 0 && (
          <TextInput
            name="Transfer Fee"
            value={`${transferFee} FET`}
            readOnly={true}
          />
        )}

        <TransferButton
          isLoading={isLoading}
          isValid={isValid}
          isPaused={isPaused}
          capReached={capReached}
          swapState={swapState}
        />
      </form>
      <WalletConnectModal
        isOpen={connectModalOpen}
        setWalletModalOpen={setConnectModalOpen}
        isLoading={connectionInProgress === 'keplr'}
        connectWallet={connectBrowserWallet}
      />
    </React.Fragment>
  );
}

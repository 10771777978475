import { Keplr } from '@keplr-wallet/types';

export function detectKeplr(): Keplr | undefined {
  // @ts-ignore
  if (typeof window?.keplr !== 'undefined') {
    // @ts-ignore
    return window.keplr;
  }
}

export function detectASIWallet(): Keplr | undefined {
  // @ts-ignore
  if (typeof window?.fetchBrowserWallet?.keplr !== 'undefined') {
    // @ts-ignore
    return window.fetchBrowserWallet.keplr;
  }
}
